import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../../layouts'
import HeaderText from '../../components/HeaderText'
import HeaderWrapper from '../../components/HeaderWrapper'
import PostLink from '../../components/PostLink'

import BlogPost from '../../interfaces/BlogPost'

interface BlogPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
      }
    }
    allMarkdownRemark: {
      edges: BlogPost[]
    }
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
        }
      }
    }
  }
`

const BlogPage: React.FC<BlogPageProps> = ({ data }: BlogPageProps) => {
  const siteTitle = data.site.siteMetadata.title
  // const { siteUrl } = data.site.siteMetadata
  const posts = data.allMarkdownRemark.edges
  const desc = 'Here are some of my musings about tech, travel, and politics.'

  return (
    <Layout>
      <Helmet>
        <title>{`Blog | ${siteTitle}`}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <HeaderWrapper>
        <HeaderText>{"Ryosuke Minami's Blog"}</HeaderText>
        <h2>{'Some of my musings about tech, travel, and politics.'}</h2>
      </HeaderWrapper>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        {posts.map(({ node }: BlogPost) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug} style={{ margin: '60px 0' }}>
              <h4 style={{ marginBottom: '22px' }}>
                <PostLink to={node.fields.slug}>{title}</PostLink>
              </h4>
              <small>{node.frontmatter.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              <p>
                <Link to={node.fields.slug}>Continue reading →</Link>
              </p>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogPage
